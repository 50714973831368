import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import "./Contact.css";
import BreadCrumps from "../common/nav/BreadCrumps";

export default function Contact() {
  return (
    <div className="container-fluid">
      <div className="me-auto navContainer">
        <BreadCrumps 
        linkTitle={"Contact Us"}/>
      </div>
      <div style={{ width: "80%" }}>
        <div className="text-start titleSection textFormat">Contact Us</div>
        <div className="contentSection textFormat">
          <table>
            <tr>
              <th>Mission</th>
              <th>Email</th>
            </tr>
            <tr>
              <td>Afghanistan</td>
              <td><a href="mailto:kblaidvsu@usaid.gov">kblaidvsu@usaid.gov</a></td>
            </tr>
            <tr>
              <td>Iraq</td>
              <td><a href="mailto:iraqaidvsu@usaid.gov">iraqaidvsu@usaid.gov</a></td>
            </tr>
            <tr>
              <td>Lebanon</td>
              <td><a href="mailto:lebanonaidvsu@usaid.gov">lebanonaidvsu@usaid.gov</a></td>
            </tr>
            <tr>
              <td>Pakistan</td>
              <td><a href="mailto:pakaidvsu@usaid.gov">pakaidvsu@usaid.gov</a></td>
            </tr>
            <tr>
              <td>Syria</td>
              <td><a href="mailto:me-syriaaidvsu@usaid.gov">me-syriaaidvsu@usaid.gov</a></td>
            </tr>
            <tr>
              <td>West Bank/Gaza</td>
              <td><a href="mailto:wbgvetting@usaid.gov">wbgvetting@usaid.gov</a></td>
            </tr>
            <tr>
              <td>Yemen</td>
              <td><a href="mailto:yemenaidvsu@usaid.gov">yemenaidvsu@usaid.gov</a></td>
            </tr>
          </table>
        </div>
      </div>
      <div style={{ width: "80%" }}>
        <div className="text-start titleSection textFormat"><p>
          {" "} For general Bureau of Humanitarian support contact{" "}
          <a href="mailto:HAVettingQuestions@usaid.gov">
            {" "}
            HAVettingQuestions@usaid.gov{" "}
          </a>{" "}
        </p></div>
        <div className="contentSection textFormat">
        <table>
            <tr>
              <th>Bureau for Humanitarian Assistance (BHA)</th>
              <th>Email</th>
            </tr>
            <tr>
              <td>BHA Afghanistan</td>
              <td><a href="mailto:HAVettingAfganistan@usaid.gov">HAVettingAfganistan@usaid.gov</a></td>
            </tr>
            <tr>
              <td>BHA Iraq</td>
              <td><a href="mailto:HAVettingIraq@usaid.gov">HAVettingIraq@usaid.gov</a></td>
            </tr>
            <tr>
              <td>BHA Lebanon</td>
              <td><a href="mailto:HAVettingLebanon@usaid.gov">HAVettingLebanon@usaid.gov</a></td>
            </tr>
            <tr>
              <td>BHA Pakistan</td>
              <td><a href="mailto:HAVettingPakistan@usaid.gov">HAVettingPakistan@usaid.gov</a></td>
            </tr>
            <tr>
              <td>BHA Syria</td>
              <td><a href="mailto:HAVettingSyria@usaid.gov">HAVettingSyria@usaid.gov</a></td>
            </tr>
            <tr>
              <td>BHA West Bank/Gaza</td>
              <td><a href="mailto:HAVettingWBG@usaid.gov">HAVettingWBG@usaid.gov</a></td>
            </tr>
            <tr>
              <td>BHA Yemen</td>
              <td><a href="mailto:HAVettingYemen@usaid.gov">HAVettingYemen@usaid.gov</a></td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  );
}
