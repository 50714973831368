import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/icons/chevron-down.svg";
import { useEffect, useState } from "react";
import "../../common/Tiles/Tiles.css";
import "./OrganizationInfo.css";
import { IaddressControlAttributes } from "../../../App.types";
import { Dropdown } from 'react-bootstrap';
import { ICountry } from "../../../Interfaces/ICountry";

import { useCountryStore } from "../../stores/CountryStore";

export default function AdditionalAddress(props: {
  mainTitle: string;
  subTitle: string;
  controlAttributes: IaddressControlAttributes;
  countryData?:ICountry[];
  disabled: boolean;
}) {

  const {countries,updateCountries} = useCountryStore();
  
  useEffect(()=>{
    if (countries.length===0) updateCountries()
  },[])

  var [showAdditionalAddress, setshowAdditionalAddress] = useState(false);
  
  const [selectedCountryOption, setSelectedCountryOption] = useState<string | null>(null);

  // Country
  const handleSelectCountry = (eventKey: any) => {
    props.controlAttributes.primaryCountryOnChange(eventKey);
    setSelectedCountryOption(eventKey as string);
  }

  //const countryDropdownItems = [ 'Afghanistan', 'Pakistan', 'Iraq', 'Lebanon', 'Syria', 'West Bank/Gaza', 'Yemen'];
  let text1 = "Complete all of the following fields:\n"; 
      text1 +=" - Country of Residence - country where residence exists\n";
      text1 +=" - Address of Residence - addresss of where residence exists\n";
      text1 +=" - City - name of city\n";
      text1 +=" - Province/Region - enter one only, either the name of the province\n";
      text1 += "  or the region or the state\n";
      text1 +=" - Zip Code - zip code of residence ";

  let newText = text1.split('\n').map((item, i) => {
                return <p key={i}>{item}</p>;
            });

  const text3 = ["This is the first line", <br/>, "This is the second"]; 
  const final = text3.toString();


  return (
    <>
      <div className="textFormat row">
        <div className="col-9 ms-3">
          <label> {props.mainTitle} </label>
          {/* <HelpDialog
                title={"Address of Prospective Subawardee"}
                // context='Please Complete all of the following fields: (Testing)' 
                context={text1}
              />           */}
          </div>
       
        <div className="col-3">
        </div>
      </div>
      <div className="rounded-2 mb-3 contentSection textFormat">
        <div className="row row-cols-2 mb-2">
          <div className="col-5 pe-0 me-0">
                <label className="label-div asterisk mb-1">Country of Residence</label>
            <Dropdown onSelect={handleSelectCountry}>
              <Dropdown.Toggle variant="success" id="dropdown-country" size="lg" role="menuitem"
                style={{ width: '98%', fontSize: "1rem", backgroundColor: 'white', color: 'black', borderColor: 'lightgrey', display: 'flex', justifyContent: 'flex-end', marginTop: '1px', lineHeight: '.6', marginLeft: '15px' }}
                disabled={props.disabled}
                >
                {props.controlAttributes.primaryCountryValue || 'Select Country of Residence'}
                <span className="caret" style={{ marginRight: 'auto', borderColor: 'lightgrey transparent transparent' }}>
                <span className="bi bi-chevron-down"></span>
                </span>
              </Dropdown.Toggle>
  
              <Dropdown.Menu  style={{ width: '98%', height: '300px', overflow:"auto", backgroundColor: 'white' }}>
                {countries?.map((item, index) => (
                  <Dropdown.Item key={index} eventKey={item.country_name}>
                    {item.country_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
         
          <div className="col flex-fill ms-3">
            <label className="label-div asterisk mb-1">Address of Residence</label>
            <div className="control-div">
              <input maxLength={150} className="form-control input-group-lg mb-1"
                placeholder="Enter Address of Residence"
                type="text"
                onChange={props.controlAttributes.primaryResidenceOnChange}
                value={props.controlAttributes.primaryResidenceValue}
                disabled={props.disabled}
              />
            </div>
          </div>
        </div>


        <div className="row row-cols-3 ms-3">
          <div className="col-4 me-5">
            <label className="row label-div asterisk">City</label>
            <div className="row control-div">
              <input className="form-control input-group-lg"
                type="text"
                placeholder="Enter City"
                maxLength={150}
                onChange={props.controlAttributes.primaryCityOnChange}
                value={props.controlAttributes.primaryCityValue}
                disabled={props.disabled}
              />
            </div>
          </div>
          <div className="col-4 me-5">
            <label className="row label-div asterisk">State/Province/Region</label>
            <div className="row control-div">
              <input maxLength={150} className="form-control input-group-lg"
                placeholder="Enter State/Province/Region"
                type="text"
                onChange={props.controlAttributes.primaryState_regionOnChange}
                value={props.controlAttributes.primaryState_regionValue}
                disabled={props.disabled}
              />
            </div>
          </div>
          <div className="col-3 flex-fill me-4">
            <label className="row label-div asterisk">Zip Code</label>
            <div className="row control-div">
              <input className="form-control input-group-lg"
                maxLength={50}
                placeholder="Enter Zip Code"
                type="text"
                onChange={props.controlAttributes.primaryZipCodeOnChange}
                value={props.controlAttributes.primaryZipCodeValue}
                disabled={props.disabled}
              />
            </div>
          </div>
        </div>

      </div>
      <span
        style={{ color: "blue" }}
        onClick={() => setshowAdditionalAddress((show) => (show = true))}
      >
        <div className="text-end fw-normal me-2 mt-1 mb-1" style={{color:"#0164B9"}}>
          {props.subTitle}
        </div>
      </span>

      {showAdditionalAddress && (
        <>
          {/* <div className="row">&nbsp;</div> */}
          <div className="textFormat row ">
            <div className="mb-1 ms-3">Alternate Address of Prospective Subawardee </div>            
          </div>
          <div className="rounded-2 orgContentSection textFormat">
            <div className="row row-cols-2 mb-2">
            <div className="col-5 pe-0 me-0">
            <label className="label-div mb-1">Alternate Country of Residence</label>
            <Dropdown onSelect={handleSelectCountry}>
              <Dropdown.Toggle variant="success" id="dropdown-country" size="lg" role="menuitem"
                style={{ width: '98%', fontSize: "1rem", backgroundColor: 'white', color: 'black', borderColor: 'lightgrey', display: 'flex', justifyContent: 'flex-end', marginTop: '1px', lineHeight: '.6', marginLeft: '15px' }}
                disabled={props.disabled}
                >
                {selectedCountryOption || 'Select Alternate Country of Residence'}
                <span className="caret" style={{ marginRight: 'auto', borderColor: 'lightgrey transparent transparent' }}></span>
              </Dropdown.Toggle>
              <Dropdown.Menu style={{ width: '98%',height: '300px', overflow:"auto",backgroundColor: 'white' }}>
              {props.countryData?.map((item, index) => (
                  <Dropdown.Item key={index} eventKey={item.country_name}>
                    {item.country_name}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
          </div>
              <div className="col flex-fill ms-3 me-4">
                <label className="label-div mb-1 ps-1">Address of Residence</label>
                <div className="row control-div">
                  <input maxLength={150}  className="form-control input-group-lg"
                    type="text"
                    placeholder="Enter Address of Residence"
                    onChange={props.controlAttributes.secondaryResidenceOnChange}
                    value={props.controlAttributes.secondaryResidenceValue}
                    disabled={props.disabled}
                  />
                </div>
              </div>
            </div>
            <div className="row row-cols-3 ms-3 mt-3">
              <div className="col-4 me-5">
                <label className="row label-div mb-1 ps-1">City</label>
                <div className="row control-div">
                  <input className="form-control input-group-lg"
                    maxLength={150}
                    placeholder="Enter City"
                    type="text"
                    onChange={props.controlAttributes.secondaryCityOnChange}
                    value={props.controlAttributes.secondaryCityValue}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              <div className="col-4 me-5">
                <label className="row label-div mb-1 ps-1">State/Province/Region</label>
                <div className="row control-div">
                  <input maxLength={150} className="form-control input-group-lg"
                    type="text"
                    placeholder="Enter State/Province/Region"
                    onChange={
                      props.controlAttributes.secondaryState_regionOnChange
                    }
                    value={props.controlAttributes.secondaryState_regionValue}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              <div className="col-3 flex-fill me-4">
                <label className="row label-div mb-1 ps-1">Zip Code</label>
                <div className="row control-div">
                  <input className="form-control input-group-lg"
                    maxLength={50}
                    placeholder="Enter Zip Code"
                    type="text"
                    onChange={props.controlAttributes.secondaryZipCodeOnChange}
                    value={props.controlAttributes.secondaryZipCodeValue}
                    disabled={props.disabled}
                  />
                </div>
              </div>
              
            </div>
          </div>
          <div className="col-12 text-end me-5 pe-2 mt-1">
              <span
                style={{ color: "blue" }}
                onClick={() =>
                  setshowAdditionalAddress((show) => (show = false))
                }
              >
                <div style={{color:"#0164B9"}}>
                - Remove Alternate Address of Prospective Subawardee 
                </div>
              </span>
            </div>
        </>
      )}
    </>
  );
}
