import "bootstrap/dist/css/bootstrap.min.css";
import "./Footer.css";
import React, { Fragment } from "react";



export default function Footer() {
  return (
    <div id="footer" className="row">
      <div className="col-3 footer">
        <span>

          &copy; Copyright 2024
        </span>
      </div>
      <div className="col-6"></div>
      {/* <div className="col-3 footer d-flex justify-content-end pe-5 footerlinks"> */}
      <div className="col-3 footerlinks">

        <FooterLinks label="Support" link="support" />        
        |
        <FooterLinks label="Privacy" link="privacy" />
        |
        <FooterLinks label="MyUSAID" link="myusaid" />
      </div>
    </div>
  );
}

function FooterLinks(props: { label: string; link: string }) {
  return (
    <div className="links">
      <a href={props.link}> {props.label}</a>
    </div>
  );
}
