
export class HelpConstants{

    public Overview: string = "NGO Portal Overview";
    public Account_Setup: string = "User Account Setup";
    public PIF_Desktop: string = "PIF Desktop";
    public Sub_PIF: string = "Sub Award PIF";
    public Ind_PIF: string = "Individual PIF";
    public Other_PIF: string = "Other PIF";
    public Non_PIF: string = "Non-award PIF";
    public Add_Ind: string = "Adding Individuals";
    public Add_Org: string = "Adding Organizations";
    public Key_Ind: string = "Key Individuals";
    public Revetting: string = "Re-vetting";
    public PIF_Desktop_Search: string = "PIF Desktop Search";
    public PIF_Sub: string = "PIF Submission";
    public Sec_Message: string = "Secure Messaging";
    public Attach_Docs: string = "Attach Documents";
    public Site_Sec: string = "Site Security";
    public Faq: string = "FAQ";
    public Gloss_Term: string = "Glossary of Terms";

    public HelpLinks = [
        {
            title: this.Overview
        },
        {
            title: this.Account_Setup
        },
        {
            title: this.PIF_Desktop
        },
        {
            title: this.Sub_PIF,
            subTitle: [this.Ind_PIF, this.Other_PIF]
        },
        {
            title: this.Non_PIF,
            subTitle: [this.Add_Ind, this.Add_Org]
        },
        {
            title: this.Key_Ind
        },
        {
            title: this.Revetting
        },
        {
            title: this.PIF_Desktop_Search
        },
        {
            title: this.PIF_Sub
        },
        {
            title: this.Sec_Message
        },
        {
            title: this.Attach_Docs
        },
        {
            title: this.Site_Sec
        },
        {
            title: this.Faq
        },
        {
            title: this.Gloss_Term
        }
    ];
}