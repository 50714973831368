import React, { useEffect } from 'react'
import errorIcon from "../../../assets/images/errorPage.jpg";
import { useNavigate } from 'react-router-dom';
import { RouteConstants } from '../../../App';

const ErrorPages = () => {

    const navigate = useNavigate();

    useEffect(()=>{
            setTimeout(()=>{
                navigate(RouteConstants.HOME_PAGE);
            },6000)
    });
    
    return (
        <div id="wrapper">
            <img src={errorIcon} />
            <div id="info">
                <h3>This page could not be found</h3>
            </div>
        </div >
    )
}

export default ErrorPages;