import 'bootstrap/dist/css/bootstrap.css';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import List from '@mui/material/ListItem';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';


import './HelpDialog.css';
import help_icon from "../../assets/images/svg/info-square-fill.svg";

import React, { useState } from "react";
import { url } from 'inspector';
import { ListSubheader } from '@mui/material';
import { LineWeight } from '@mui/icons-material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


export default function HelpDialog ( props: {title: string, context:string,} ) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
    '& .MuiDialogTitle-root': {
        fontWeight:'bold',
    }
  }));


  return (
      <>
    <IconButton onClick={handleClickOpen}>
      {/* <img  src={help_icon} /> */}
      <FontAwesomeIcon icon="circle-info" size="xs" className="help-icon" style={{fontSize: '18px'}}/>
    </IconButton>
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={open}
    >
      <DialogTitle sx={{ m: 0, p: 3 }} id="customized-dialog-title" className="help-title">
        {/* <img  src={help_icon} />  {props.title} */}
        <FontAwesomeIcon icon="circle-info" size="xs" className="help-icon" style={{fontSize: '24px', marginRight: '0.25em' }}/> {props.title}
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
          img:{help_icon},
          whiteSpace: "pre-wrap" 
        }}
        >

        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
          {/* <List sx={{display:"list-item", listStyle:"none"}}>
              <ListItem component="dt"> {props.term1}</ListItem>
              <ListItem component="dd">{props.desc1}</ListItem>                
          </List> */}
        
        <Typography  variant='body2' component="pre">
          {props.context}
        </Typography>
        
        {/*}
        <Typography  component="dt" variant='h6'>
          {props.term2}
        </Typography>
        <Typography  component="dd" variant='subtitle2'>
          {props.desc2}
        </Typography> */}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          close
        </Button>
      </DialogActions>
    </BootstrapDialog>
              
          </>
  )
}
