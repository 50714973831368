import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import './Login.css';

import { SetStateAction, useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import axios from "axios";
import React from "react";

export default function Auth() {
  const [text, setText] = useState("Authenticating");
  const navigate = useNavigate();
  const basePath = process.env.REACT_APP_API_URL;



  useEffect(() => {
   
    const timeoutId = setTimeout(()=> {
      checkAccessTokenPresent();
      },1000) //1 Second delay looks a little better
  
    return () =>clearTimeout(timeoutId)

  }, [])
 
  async function checkAccessTokenPresent(){
    const searchParams= new URLSearchParams(window.location.hash)
    //If access token in URL we can do authentication
    if (searchParams.has("#access_token")===true){
      const token = (searchParams.get("#access_token"));
      try{
        const axiostInstace = axios.create({
          baseURL: basePath+ '/authenticate',
          withCredentials: true,
        })
        const response = await axiostInstace.post('', token);
        console.log(response.data);
        const jwtCookie= document.cookie.length;
        console.log(jwtCookie);
        if(response.data.user_email){
          navigate('/dashboard');
        }
        setText(response.data.Error)
      } catch(error){
          console.log(error);
          setText("Error")
      }
    } 
}
  
    



  
  

  return (
    <div className="container-fluid login">
        <h5 style={{textAlign: "center"}}>{text}</h5>
    </div>
  );
}
