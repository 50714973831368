import editIcon from "../../../assets/images/svg/edit.svg";

export default function EditCellRenderer(props: any) {
  return (
    <div>
      <button
        type="button"
        style={{backgroundColor:"transparent"}}
        className="btn-link"
        onClick={() => props.onClick(props.data.cellId?? 0)}
      >
        <img src={editIcon} alt="logo" />
      </button>
    </div>
  );
}
