import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import { useState } from 'react';
import icon_people from "../../../assets/images/svg/people.svg";
import icon_person from "../../../assets/images/svg/person.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLandmark } from '@fortawesome/free-solid-svg-icons';
import "./SelectFormType.css";
import { useNavigate } from "react-router-dom";
import { AwardTypes, RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { alignProperty } from '@mui/material/styles/cssUtils';


export default function SelectFormType() {
    const { updateFormType, awardType } = useNavStore();
    const items: { value: string, label: string, imageName: any, color: string }[] = [
        { value: 'organization', label: 'Organization', imageName: faLandmark, color: "white" },
        { value: 'individual', label: 'Individual', imageName: faUser, color: "white" }
    ];

    // Use name to hold the text  based on awardType value from NavStore:  primeaward | subaward | nonaward
    const name = (awardType === "primeaward") ? "Prime Awardee" : (awardType === "subaward") ? "Subawardee" : (awardType === "nonaward") ? "Non-Awardee" : (awardType === "revet") ? "Re-Vet": null;
    const [value, setValue] = useState("");
    const [hiddenButtons, setHiddenButtons] = useState<boolean>(false);

    const navigate = useNavigate();

    return (
        <div className="text-center container-fluid">
            <h3 className="text-center mb-4 font-weight-italic text-dark">Create {name} </h3>

            <span className="heading">
                <h4 className="text-center text-dark asterisk">Select {name} Type</h4>
            </span>
            <div className="btnSection">
                {items.map(item => (
                    <div key={item.value}>

                        <label htmlFor={name + item.value}>
                            <div>
                                <button className="btn btn-large d-flex align-items-center mx-2" type="button" role="button"
                                    style={{minWidth:"12em"}}
                                    onClick={() => {
                                        // set store value of form type and navigate to appropriate form with path variable
                                        updateFormType(item.value);
                                        setHiddenButtons(true);
                                        navigate(RouteConstants.SUB_AWARD_INFO, { state: { formType: item.value } })
                                    }}
                                >
                                    <FontAwesomeIcon className="ps-1 pe-3 fa-fw" icon={item.imageName} fontSize={40} color={item.color} />
                                    <span className="align-middle"> {item.label} </span>

                                </button>
                            </div>
                        </label>

                    </div>
                    // </div>
                ))}
            </div>
        </div>
    );
}


