import "bootstrap/dist/css/bootstrap.min.css";
import "./OrganizationInfo.css";
import TextField, { FilledTextFieldProps, OutlinedTextFieldProps, StandardTextFieldProps, TextFieldVariants } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { SyntheticEvent, useEffect, useState } from "react";
import { getOrganizations, searchIndividuals, searchOrganizations } from "../../../Services/Api";
import { ISearch } from "../../../Interfaces/ISearch";
import { JSX } from "react/jsx-runtime";

interface IProps{
  section:string;
  placeHolderText:string;
  disabled: boolean;
  searchOnChange(event: SyntheticEvent<Element, Event>,search:ISearch|null):void;
}
export default function OrganizationSearch(prop: IProps) {
  const [selectData, setSelectData] = useState<ISearch[]>([])
  
/* 
  using static values for mission and user organization
*/
  async function fetchOrganizations(input: string){
    if(input===null) return ;
    if(input.length > 1){
      var result:ISearch[]=[];
      if(prop.section==="organization") 
         result = await searchOrganizations(input);

      else if (prop.section==="individual") 
        result = await searchIndividuals(input);
 
      setSelectData(result);
    }
  }
  

  return (
    <>
      <label className="row orgTextFormat ms-4 mb-1">Start typing characters to select existing {prop.section} (2 characters minimum):</label>
      <Autocomplete size="small"
        // className="form-control input-group-md ms-4"
        style={{marginLeft:"22px"}}
        disablePortal
        forcePopupIcon={false}
       
        id="combo-box-demo"
        options={selectData}
        renderOption={(props, option: ISearch)=>(
          <li {...props} key={option.objectId}>{option.objectName}</li>)}
        getOptionLabel={(x: ISearch) => x.objectName}
        renderInput={(params: JSX.IntrinsicAttributes & { variant?: TextFieldVariants | undefined; } & Omit<FilledTextFieldProps | OutlinedTextFieldProps | StandardTextFieldProps, "variant">) => <TextField {...params}  onChange={(e)=>fetchOrganizations(e.target.value)} />}
        sx={{ height:50, "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
        {
          borderColor: "none",
        },}}
        onChange={prop.searchOnChange}
        filterOptions={(x: any)=>x}
        disabled={prop.disabled}
      />
    </>
  );
}
