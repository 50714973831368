interface Errors{
    primeAward?: string,
    awardType?: string,
    subawardValue?: string,
    country?: string,
    province?: string,
    startDate?: string,
    endDate?: string,
    purpose?: string,
}

const validateFields = (
    selectedOptionAward: any, 
    selectedAwardType: any, 
    selectedCountryOption: any, 
    valueOfTotalSubAward: any, 
    location: any, 
    startDate: any, 
    endDate: any, valueofpurposeofProposed: any) => {
    
    const startDateTime = new Date(startDate).getTime();
    const endDateTime = new Date(endDate).getTime();
    const errors: Errors = {};

    if (selectedOptionAward === null || selectedOptionAward === 'Select an Award') {
        errors.primeAward = 'Please select a Prime Award.\n'
      }
  
      if (selectedAwardType === null || selectedAwardType === 'Select an Award Type') {
        errors.awardType = 'Please select an Award Type/Vetting Rule Name.\n'
      }
      if (selectedCountryOption === null || selectedCountryOption === 'Select a Country') {
        errors.country = 'Please select a Country.\n'
      }
      if (valueOfTotalSubAward === 0) {
        errors.subawardValue = 'Please fill in Value of Total Subaward(USD).\n';
      }
  
      if (location === null) {
        errors.province = 'Please fill in value for Province/Region.\n'
      }
  
      if (startDate === null) {
        errors.startDate = "Please fill in a Start Date.\n"
      }
      if (endDate === null) {
        errors.endDate = "Please fill in an End Date.\n"
      }
  
      if (valueofpurposeofProposed === null) {
        errors.purpose = 'Please fill in Value of Proposed Award or Assistance.\n'
      }
  
      if (startDateTime > endDateTime) {
        errors.startDate = 'End Date is Before Start Date.\n'
      }

    return errors;
};

export default validateFields;
export type { Errors };
