import { useState, ReactNode} from "react";
import "bootstrap/dist/css/bootstrap.min.css";

type myProps = {
  title: string,
  // toggleLoginButton: () => void,
  isChecked: boolean,
  subHeadings?: boolean,
  footNote: string[],
  children: React.ReactNode
}

export default function CertificationParent ({children} :any) {

  
  return (
    <div> 
        {children}
      </div>   
  );
}
