import { useState } from "react";
import "./OrganizationInfo.css";
import { IcontrolAttributes } from "../../../App.types";
import { ICountry } from "../../../Interfaces/ICountry";
import HelpDialog from "../../common/HelpDialog";

export default function AdditionalFaxNumber(prop: {
  title: string;
  isAddNumberNeeded: boolean;
  controlAttributes: IcontrolAttributes;
  countryData:ICountry[];
  disabled: boolean;
}) {

  const filteredCountryCode =  prop.countryData?.filter(x=> x.country_code);

  var [showAdditionalFaxNumber, setshowAdditionalFaxNumber] = useState(false);

  let text = 'Please enter the country code and area/city code and full fax phone number.\n';
      text += ' if no number exists, enter - 000-000-0000';
  return (
    <>
      <div className="textFormat row"></div>
        <div className="col">
          <label className="label-div form-label">{prop.title}</label>
          {/* <HelpDialog
                title={"Primary Fax Number (If Applicable)"}
                context= {text}
              /> */}
        </div>
      
      <div className="row">
        <div className="col">
        <select className="form-select" 
                style={{ width: "100%" }}
                disabled={prop.disabled}
                defaultValue="">
          <option value="" disabled> Code </option>
            {filteredCountryCode.map((options, i) => (
                    <option key={i} value={options.country_code}>
                      {options.country_name +" (" + options.country_code +")"}
                    </option>
            ))}
                </select>
        </div>
        <div className="col">
          <input className="form-control input-group-lg"
             style={{ width: "100%" }}
            maxLength={50}
            placeholder="000-000-0000"
            type="text"
            name="fax-num"
            onChange={prop.controlAttributes.primaryOnChange}
            value={prop.controlAttributes.primaryTextValue}
            disabled={prop.disabled}
          />
        </div>

        {/* <div className="col-4"></div> */}
      </div>
      { prop.isAddNumberNeeded && (
      <div className="col label-div mt-0">
        <span
          style={{ color: "blue" }}
          onClick={() =>
            setshowAdditionalFaxNumber((show) => (show = true))
          }
        >
          <div className="text-end mt-1">
            + Add Number
          </div>
        </span>
      </div>)
    } 

      {showAdditionalFaxNumber && (
        <>
          <div className="textFormat row">
            <div className="col">
              <label className="label-div mb-1 ps-1">{prop.title}</label>
            </div>
            <div className="col label-div">
              <span
                style={{ color: "blue" }}
                onClick={() =>
                  setshowAdditionalFaxNumber((show) => (show = false))
                }
              >
                - Remove Number
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col control-div">
              <select className="form-select" style={{ width: "100%" }}    
              defaultValue="">
              <option value="" disabled> Code </option>
                {filteredCountryCode.map((options, i) => (
                        <option key={i} value={options.country_code}>
                          {options.country_name +" (" + options.country_code +")"}
                        </option>
                ))}
                </select>
              </div>
              <div className="col">
              <input className="form-control input-group-lg"
                type="text"
                style={{ width: "100%" }}
                maxLength={50}
                placeholder="000-000-0000"
                onChange={prop.controlAttributes.secondaryOnChange}
               value={prop.controlAttributes.secondaryTextValue}
               disabled={prop.disabled}
            />
            </div>
            <div className="col"></div>
          </div>
      </>
      )}
    </>
  );
}