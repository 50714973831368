import "bootstrap/dist/css/bootstrap.min.css";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import {
  ColDef, ColGroupDef, GridApi,  GridReadyEvent,
  ITextFilterParams, ValueGetterParams, IDateFilterParams,  IRowNode,
  IFilterOptionDef
} from "ag-grid-community";
import { useMemo, useState, useCallback, useRef, useEffect } from "react";

import delete_icon from '../../../assets/images/svg/delete.svg';
import { IPifDashboardData } from "../../../Interfaces/IPifDashboardData";
import "./Dashboard2.css";
import "./CustomLoadingCell";
import { useNavigate } from "react-router-dom";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import MessageDialog from "../../common/message/MessageDialog";
import PDFViewer from "../../common/PDF/PDFViewer";
import DocumentsViewer from "../../common/DocumentUploads/DocumentsViewer";
import { useFormProps } from "../../stores/FormProperties";
import { IComment } from "../../../Interfaces/IComment";
import { updateComments } from "../../../Services/Api";


const dateFilterParams: IDateFilterParams = {

  filterOptions: [

    "equals",
    {
      displayKey: "equalsWithNulls",
      displayName: "Equals (with Nulls)",
      predicate: ([filterValue]: any[], cellValue) => {
        if (cellValue == null) return true;
        var parts = cellValue.split("-");
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0]),
        );
        return cellDate.getTime() === filterValue.getTime();
      },
    },
    {
      displayKey: "betweenExclusive",
      displayName: "Between (Exclusive)",
      predicate: ([fv1, fv2]: any[], cellValue) => {
        if (cellValue == null) return true;
        var parts = cellValue.split("-");
        var cellDate = new Date(
          Number(parts[2]),
          Number(parts[1] - 1),
          Number(parts[0]),
        );
        // printing values
        return (
          cellDate.getTime() > fv1.getTime() &&
          cellDate.getTime() < fv2.getTime()
        );
      },
      numberOfInputs: 2,
    },
  ] as IFilterOptionDef[],

  comparator: (dateComparator: Date, cellValue: string) => {
    var dateAsString = cellValue;
    if (dateAsString == null) return -1;
    var dateParts = dateAsString.split("-");
      var cellDate = new Date(
      Number(dateParts[2]),
      Number(dateParts[1]) - 1,
      Number(dateParts[0]),
    );



    // console.log("CellDate: " + cellDate + "CellValue | dateAsString: " + dateAsString);
    if (dateComparator.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < dateComparator) {
      return -1;
    }
    if (cellDate > dateComparator) {
      return 1;
    }
    return 0;
  },
};

const navRoute = "/subaward/awardinfo";


{/* Just an Exmple - Not used */ }
const CustomButtonComponent = () => {
  return <button onClick={() => window.alert("clicked")}>Push Me!</button>;
};



const GridExample = () => {

  const containerStyle = useMemo(() => ({ width: 1700, height: 700}), []);
  const gridStyle = useMemo(() => ({ width: "90%", height: "80%", Padding: "5px",  Margin:"5px"}), []);
  const { clearNavStore, updateFormType, updateAwardType } = useNavStore();
  const [searchValues, setState] = useState<{ searchString: string, statusString: string, dateCreated: [Date, Date] | null | undefined, dateStarted: [Date, Date] | null | undefined }>(
    {
      searchString: "",
      statusString: "Any",
      dateCreated: undefined,
      dateStarted: undefined,
    });

  const defaultColDef = useMemo<ColDef>(() => {
    return {
      cellStyle: { fontWeight: 'normal' },
      filter: true,
      flex: 1,
      cellDataType: false,
    };
  }, []);
  
  const {updateFormProperties} = useFormProps();
  const gridRef = useRef<AgGridReact>(null);

  const dateFilterParams = {
    comparator: function (filterLocalDateAtMidnight: Date, cellValue: string) {
      var dateAsString = cellValue;
      if (dateAsString == null) return -1;
      var dateParts = dateAsString.split("-");
      var cellDate = new Date(dateAsString);

      if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
        return 0;
      }
      if (cellDate < filterLocalDateAtMidnight) {
        return -1;
      }
      if (cellDate > filterLocalDateAtMidnight) {
        return 1;
      }
    },
    buttons: ["reset", "apply"] as ITextFilterParams, Placeholder: "Date Placeholder",
  };

  const gridOptions = {
    suppressServerSideFullWidthLoadingRow: true,
    autoSizeStrategy: {
      type: 'fitGridWidth',
      defaultMinWidth: 90,
    },
    isExternalFilterPresent: isExternalFilterPresent,
    doesExternalFilterPass: doesExternalFilterPass,
  };

  const [rowData, setRowData] = useState<IPifDashboardData[]>();
  // Use to apply date  filter if set
  const [dateFilter, setDateFilter] = useState(false);

  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const updateCommentsData = (comment:IComment[])=>{
    //comments here and update them and call pass a list to the api
    const requestData: string[] = comment.reduce((x:string[],y)=>[...x,y.commentObjectId],[],);
  if(rowData){
      const updatedRowData: IPifDashboardData[] = rowData?.map(x=>{
        if(x.comments){
        const updatedComments = x.comments.map(y=>{
          if(requestData.includes(y.commentObjectId)){
            y.commentRead=true;
            return y;
          }
          return y;
        })
        let temp = x;
        temp.comments = updatedComments;
        return temp;
        }
        return x;
      })

      setRowData(updatedRowData);
    }

    updateComments(requestData);
  }

  // Represents data in the Grid-Defined by the Interface
  let gridApi: GridApi<IPifDashboardData>;

  // Custom Components (mainly to create images)
  const MessageComponent = (props: { pifid: string | undefined, pifstatus: string | undefined,pifComments: IComment[] | undefined,updateComments:any}) => {
    return (
      <div>
        
        <MessageDialog
          title={"Prime Award"}
          context='Prime Award name to which the subaward is based'
          pifid={props.pifid}
          pifstatus={props.pifstatus}
          pifComments={props.pifComments}
          updateComment={props.updateComments}
        />
      </div>


    )
  };



  // {
  //   headerName: "PIF ID",
  //   cellRenderer: function (params: any) {
  //     return <a
  //       onClick={() => {
  //         clearNavStore();
  //         updateFormType("organization");
  //         updateAwardType("subaward");
  //         navigate(RouteConstants.SUB_AWARD_INFO, {
  //           state: {
  //             pifId: params.data.pifId,
  //             formType: "organization"
  //           }
  //         })
  //       }}
  //     > {params.data.pifId} </a>;
  //   },
  //   valueGetter: (p: ValueGetterParams) => p.data.pifId, colId: "idPifID",
  //   filter: 'agTextColumnFilter',
  // },



  const PDFComponent = (props: { pifid: string }) => {
    return (
      <div>
        <PDFViewer
          pifid={props.pifid}
        />
      </div>

    )
  };

  const DeleteComponent = () => {
    return (
      <img className='delete_icon' src={delete_icon} alt="delete icon" />

    )
  };

  const [viewDocument, setViewDocument] = useState(false);

  const handleButtonClick = () => {
    setViewDocument(true);
  };

  const DocumentsComponent = (props: { pifid: string }) => {
    return (
      <div>
        <DocumentsViewer
          pifid={props.pifid}
        />
      </div>
    )
  };

  const loadComments=(data:any)=>{
    console.log("data=>" , data);

    return data;
  };


  //-----------------------------------------------------
  // json fields:
  //-----------------------------------------------------
  //       "pifId": "PIF-100-4",
  //       "createdDate": "2024-Apr-22",
  //       "pifStatus": "SUBMITTED",
  //       "awardeeName": "",
  //       "purposeOfSubAward": "TEST Purpose2332_ABCCCCC",
  //       "awardAmount": "3231.00",
  //       "startDate": "2024-Mar-25"
  //--------------------------------------------------------
  /* 
  * Input: cellValue: string (date-inferred but in string format)
  * Output:  string
   * Converts date from yyyy-MMM-dd format to:  dd-MMM-YYYY
   * [ e.g.,]
   * 2024-May-10 =>  10-May-2024
   * *
  */


  function dateFormatter(cellValue: string): any {
    const dateAsString = cellValue;

    if (dateAsString === undefined || dateAsString === null) {
      return "";
    }
    // yyyy-MMM-dd => dd-MMM-yyyy
    const year = dateAsString.substring(0, 4);

    const month = dateAsString.substring(5, 8);

    const day = dateAsString.substring(9, 11);

    const result = `${day}-${month}-${year}`;
    return result;

  }

  // DATE COMPARATOR FOR SORTING
  function dateComparator(date1: string, date2: string) {
    // date2 = dateFormatter(date2);

    // Trying to sort on using yyy-MMM-dd instead


    const date1Number: any = _monthToNum(date1);
    const date2Number: any = _monthToNum(date2);


    if (date1Number === null && date2Number === null) {
      return 0;
    }
    if (date1Number === null) {
      return -1;
    }
    if (date2Number === null) {
      return 1;
    }
    return date1Number - date2Number;
  }

  // HELPER FOR DATE COMPARISON
  function _monthToNum(date: string) {

    // now incoming date: yyyy-MMMM-dd
    if (date === undefined) {
      return null;
    }

    // Object to convert month names to number
    const months: any = {
      Jan: 1,
      Feb: 2,
      Mar: 3,
      Apr: 4,
      May: 5,
      Jun: 6,
      Jul: 7,
      Aug: 8,
      Sep: 9,
      Oct: 10,
      Nov: 11,
      Dec: 12
    }


    const yearNumber = Number.parseInt(date.substring(0, 4));

    let monthNumber: any = date.substring(5, 8);
    monthNumber = months[monthNumber];

    const dayNumber = Number.parseInt(date.substring(9,));

    let result = yearNumber * 10000 + monthNumber * 100 + dayNumber;



    // 08-Aug-2009 => 20040829
    return result;
  }

  // TODO: Create Custom Date filter for grid table field: createdDate
  const createdDateFilter = () => {

  }

  // Set States for Input Fields
  const [dateCreatedSearchRange, SetDateCreatedSearchRange] = useState<[Date, Date] | null | undefined>();
  const [pifStatus, setPifStatus] = useState<any>("Any");
  const [dateStartSearchRange, SetDateStartSearchRange] = useState<[Date, Date] | null | undefined>();

  useEffect(() => {
    console.log("Created Search Range Changed: " + dateCreatedSearchRange);

  }, [dateCreatedSearchRange]);




  function handleDateChangeDateCreated(value: [Date, Date] | null | undefined, event: React.SyntheticEvent<Element, Event>) {
    SetDateCreatedSearchRange(value);
    // setDateFilter(value);

    if (value == null) { return "" };
    const startDateObj = value[0];
    const endDateObj = value[1];
    // const date1:String = value[0].toLocaleString();
    const date1 = DateToStr(value[0]);
    setStartDate(date1);
    // const date2:String  = value[1].toLocaleDateString();
    const date2 = DateToStr(value[1]);
    setEndDate(date2);

    // Filter Columns on Grid
    var dateFilterComponent = gridRef.current?.api.getFilterInstance('idCreationDate');
    dateFilterComponent?.setModel({
      type: 'inRange',
      dateFrom: "01-Jul-2024",
      dateTo: "08-Jul-2024",
    });
    gridRef.current?.api.setGridOption(
      "quickFilterText",
      (document.querySelector('#pifCreationDate') as HTMLInputElement).value);
    gridRef.current?.api.onFilterChanged();

  }

  function handleDateChangeDateStart(value: [Date, Date] | null | undefined, event: React.SyntheticEvent<Element, Event>) {
    SetDateStartSearchRange(value);
    // set to value of column
  }

  function handleClear(e: any) {
    gridRef.current?.api.setFilterModel(null);
    setPifStatus("Any");
    SetDateCreatedSearchRange(null);
    SetDateStartSearchRange(null);
    setState(
      {
        searchString: "",
        statusString: "Any",
        dateCreated: null,
        dateStarted: null
      }
    );
  }

  function handleStatusChange(e: React.ChangeEvent<HTMLSelectElement>) {
    const pifVal = e.target.value;
    setPifStatus(pifVal);
    if (pifVal.includes("All")) {
      gridRef.current!.api.setGridOption('quickFilterText', "")
      handleClear(pifVal);
      gridRef.current?.api.onFilterChanged();
      // return;
    }
    gridRef.current?.api.setGridOption(
      "quickFilterText",
      (document.querySelector('#status_select') as HTMLInputElement).value);
    gridRef.current?.api.onFilterChanged();

  };

  const clearFilters = useCallback(() => {
    gridRef.current!.api.setFilterModel(null);
  }, []);


  const restoreState = useCallback(() => {
    console.log("filter state restored");
  }, [window]);


  const resetState = useCallback(() => {
    gridRef.current?.api.resetColumnState();
  }, []);


  // // Explore further----implement this one
  const restoreFilterModel = useCallback(() => {
    gridRef.current?.api.getFilterModel();
    // gridRef.current?.api.onFilterChanged();
  }, []);



  function DateToStr(date: Date) {
    const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const year = date.getFullYear();
    const month = monthNames[date.getMonth()];
    const day = date.getDate().toString().padStart(2, '0'); //Make sure day is two digit

    // return `${year}-${month}-${day}`;
    return `${day}-${month}-${year}`;
  }

  function filterLabel() {
    let message = []


    if (searchValues.statusString !== "Any") {
      message.push("Pif Status:" + searchValues.statusString);
    }
    if (searchValues.dateCreated !== null && searchValues.dateCreated !== undefined) {
      message.push("PIF Creation Date: [" + DateToStr(searchValues.dateCreated[0]) + "~" + DateToStr(searchValues.dateCreated[1]) + "]");

    }

    if (searchValues.dateStarted !== null && searchValues.dateStarted !== undefined) {
      message.push("Award Date: [" + DateToStr(searchValues.dateStarted[0]) + "~" + DateToStr(searchValues.dateStarted[1]) + "]");

    }
    return message;
  }

  const [roles, setRoles] = useState("");
  const navigate = useNavigate();


  const redirectToRegister = () => {
    if (roles === "Vetting Official 001") { navigate("/Register") }
    else if (roles === "PVS System Admin") { navigate("/Register") }
    else if (roles !== "Vetting Official 001" || "PVS System Admin") {
      alert('Must Be Vetting Official or PVS Administrator')
    }
  };


  function isFilterApplied() {
    return filterLabel().length > 0;
  }

  function handleClick(e: React.ChangeEvent<any>) {
    e.preventDefault();
    let statusIn = pifStatus;
    let dateCreatedIn = dateCreatedSearchRange;
    let dateStartedIn = dateStartSearchRange;
    setDateFilter(true);

    setState(prevSearchValues => {
      return {
        searchString: prevSearchValues.searchString,
        statusString: statusIn,
        dateCreated: dateCreatedIn,
        dateStarted: dateStartedIn
      }
    })

  }

  // Format Award Amt - seperate numerals by commas
  function currencyFormatter(currency: any, sign: any) {
    if (currency) {
      var sansDec = currency.toString();
      var formatted = sansDec.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return sign + `${formatted}`;
    } else {
      return "";
    }
  }





  const [columnDefs, setColumnDefs] = useState<(ColDef<any, any> | ColGroupDef<any>)[]>([

    {
      headerName: "PIF Status", field: "pifStatus", colId: "idPifStatus", headerTooltip: "PIF Status",
      valueGetter: (p: ValueGetterParams) => p.data.pifStatus, flex: 2,
      filter: 'agTextColumnFilter',
      filterParams: {
        buttons: ["reset", "apply"] as ITextFilterParams,
      },
      getQuickFilterText(params) {
        return params.data.pifStatus;
      }, 
    },

    {
      headerName: "PIF ID",
      cellRenderer: function (params: any) {
        return <a
          onClick={() => {
            clearNavStore();
            updateFormType("organization");
            updateAwardType("subaward");

            updateFormProperties(params.data.pifId,params.data.pifStatus,params.data.returnedReason);
            
            navigate(RouteConstants.SUB_AWARD_INFO, {
              state: {
                pifId: params.data.pifId,
                formType: "organization",
              }
            })
          }}
        > {params.data.pifId} </a>;
      },
      valueGetter: (p: ValueGetterParams) => p.data.pifId, colId: "idPifID", flex: 2,
      filter: 'agTextColumnFilter', cellStyle: {'color':'blue', 'text-decoration': 'underline', 'cursor': 'pointer' },
    },

   
    {
      headerName: "Creation Date", colId: "idCreationDate",
      valueGetter: (p: ValueGetterParams) => p.data.createdDate,
      filter: 'agDateColumnFilter', flex: 3,
      valueFormatter: p => dateFormatter(p.data.createdDate),
      comparator: dateComparator,
      sortable: true, sortingOrder: ["asc", "desc"],
      filterParams: dateFilterParams,
    },

    // {
    //   headerName: "Purpose of Subaward", colId: "idPurposeOfSubaward",
    //   valueGetter: (p: ValueGetterParams) => p.data.purposeOfSubAward,
    //   filter: 'agTextColumnFilter', flex: 2,
    // },

     {
      headerName: "Prime Award #",
      valueGetter: (p: ValueGetterParams) => p.data.awardNumber,
      filter: 'agTextColumnFilter', colId: "idAwardNumber", flex: 3,
    },

    {
      headerName: "Awardee Name",
      valueGetter: (p: ValueGetterParams) => p.data.primeAwardeeName,
      filter: 'agNumberColumnFilter', colId: "idPrimeAwardee", flex: 3,
    },


    {
      headerName: "Award Amount (USD)",
      valueFormatter: p => currencyFormatter(p.data.awardAmount, '$'),
      valueGetter: (p: ValueGetterParams) => p.data.awardAmount,
      filter: 'agNumberColumnFilter', colId: "idAwardAmount", flex: 4,
    },

    {
      headerName: "Award Start Date",
      valueGetter: (p: ValueGetterParams) => p.data.startDate,
      filter: 'agDateColumnFilter', flex: 3, colId: "idAwardStartDate",
      valueFormatter: p => dateFormatter(p.data.startDate),
      comparator: dateComparator, sortable: true, sortingOrder: ["asc", "desc"],
      filterParams: dateFilterParams,

    },
    {
      headerName: "Messages",
      cellRenderer: MessageComponent,
      cellRendererParams: (params: any) => ({
        pifid: params.data.pifId,
        pifstatus: params.data.pifStatus,
        pifComments: params.data.comments,
        updateComments: (input: IComment[])=>updateCommentsData(input)
      }),
      sortable: false, flex:2,
    },
    {
      headerName: "PDF",
      cellRenderer: PDFComponent,
      cellRendererParams: (params: any) => ({
        pifid: params.data.pifId,
        pifstatus: params.data.pifStatus
      }),
      sortable: false, colId: "idColID", flex: 2,
    },
    // {
    //   headerName: "View ID Document(s)",
    //   cellRenderer: DocumentsComponent, sortable: false, colId: "idDocuments",

    // },
    {
      headerName: "Delete",
      cellRenderer: DeleteComponent, sortable: false, colId: "idDelete", flex:2,
    },



  ]);

  const quickFilterText = '';


  // Set filter on column here, e.g., for date range in Creation Date
  // if date is between startDate rowDodeand endDate 

  let creationDate = new Date();

  // var filtered = {
  //   dateFrom: "22-Apr-2224", //the date which you selected in AGGridDateFilter Popup
  //   dateTo: "10-May-2024", //the date which you selected in AGGridDateFilter Popup
  //   filterType: "date",
  //   type: "inRange"
  // };

  function isExternalFilterPresent(): boolean {
    // if true, then we are filtering
    return creationDate !== null;
  }


  function doesExternalFilterPass(node: IRowNode<IPifDashboardData>): boolean {
    if (node.data) {

    }
    return true;
  }


  // TEST
  let checkVar = "everybody";

  const externalFilterChanged = useCallback((newValue: string) => {
    checkVar = newValue;
    gridRef.current!.api.onFilterChanged();
  }, []);




  // const primeListURL:string = process.env.REACT_APP_API_URL + "http://local:3000/pifs/list";
  const primeListURL: string = process.env.REACT_APP_API_URL + "/pifs/list";



  // Set Data from API Call
  // const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(
    {
      message: "Error with fetching pif API data",
    }
  );

  const onGridReady = useCallback((params: GridReadyEvent) => {

    fetch(primeListURL, { credentials: 'include' })
      // fetch(primeListURL)
      .then((resp) => {
        if (resp.ok) {
          console.log("Fetch was successful");
          return resp.json();
        }
      })
      .then((data: IPifDashboardData[]) => setRowData(data))
      .catch(error => {
        setError(error);
        // setLoading(false);
      });
    if (loading) return <p className="fs-1">Loading...</p>;


  }, []);

  const onFilterTextBoxChanged = useCallback(() => {
    gridRef.current!.api.setGridOption(
      "quickFilterText",
      (document.querySelector('#filter-text-box') as HTMLInputElement).value,
    );
  }, []);

  const saveFilterModel = useCallback(() => {
    const savedFilterModel = gridRef.current!.api.getFilterModel();
    var keys = Object.keys(savedFilterModel);
    var savedFilters: string = keys.length > 0 ? keys.join(", ") : "(none)";
    (document.querySelector("#savedFilters") as any).textContent = savedFilters;
  }, []);




  return (
    <div className="border border-1 rounded-1 text-format" style={containerStyle}>

      <div className="mb-1 ms-4 text-start">
        <div className="row">
          <div className="col-6">
            <p className="mt-2 mb-"> Search (ID#, PIF ID,  Awardee Name)</p>
          </div>
        </div>
        <div className="row">
          <input className="col-6 mb-4"
            type="text"
            id="filter-text-box"
            // placeholder="Search (Govt ID, Awardee Name, etc)"
            onInput={onFilterTextBoxChanged}
          />
        </div>
      </div>
      {/* <div className="d-flex justify-content-between col-6"> */}
      <div className="d-flex justify-content-center col-6">
        {/* <span className="ms-4"> <strong> Filters: </strong> Clear All Filters </span>
        <span> Show Filters / Hide Filters </span> */}
      </div>
      {/* <div className="row mb-2 justify-content-center">
      <div className="flex-container me-auto mb-4 ms-3 p-2 border border-1 rounded-3 col-6">

        <div className="col-3 ms-3">

          <p className="font-weight-normal ms-4 me-2" style={{ marginBottom: '0px' }}>PIF Status</p>
          <select value={pifStatus} onChange={handleStatusChange} name="status_filter" className="form-select" id="status_select">
            <option value="All (Select Filter)">All (Select Filter)</option>
            <option value="Draft">Draft</option>
            <option value="Created">Created</option>
            <option value="Submitted - Clarification Requested">Submitted - Clarification Requested</option>
            <option value="Submitted">Submitted</option>
            <option value="Returned">Returned</option>
            <option value="Accepted">Accepted</option>
          </select>
        </div>
        </div>  {/* Extra div - remove if putting back any components below */}
      {/* </div>  */}


      {/* <div className="col-3">
          <p className="font-weight-normal" style={{ marginBottom: '0px' }}>PIF Creation Date</p>
          <img className='calendar_icon' src={icon_calendar} alt="logo" /> */}

      {/* <DateRangePicker hidden={hideCalendar} open={!hideCalendar} onOk={handleOkCalendar}/> */}
      {/* <DateRangePicker format={('dd-MMM-yyyy')}
            id="pifCreationDate"
            value={dateCreatedSearchRange}
            placeholder="dd-mmm-yyyy"
            onOk={handleDateChangeDateCreated}
          />

        </div> */}

      {/* <div className="col-3">
          <p className="font-weight-normal" style={{ marginBottom: '0px' }}>Award Date</p>
          <img className='calendar_icon' src={icon_calendar} alt="logo" /> */}

      {/**<DateRangePicker hidden={hideCalendar} open={!hideCalendar} onOk={handleOkCalendar}/>*/}
      {/* <DateRangePicker format={('dd-MMM-yyyy')}
            id="savedFilters"
            value={dateStartSearchRange}
            placeholder="dd-mmm-yyyy"
            onChange={handleDateChangeDateStart}
          />
        </div> */}

      {/* <div className="col-2" style={{}}>
          <span className="align-content-start">
            <br></br>
            <button type="submit" className="btn btn-primary" onClick={handleClick}>
              Apply

            </button>
          </span>
        </div>
      </div> End of 2nd Row
      <div> */}
      {/* <a href="#">Filters({filterLabel().length})</a>
        {
          filterLabel().map(x =>
            <a href="#" className='text-muted' style={{ backgroundColor: "lightgray", marginLeft: ".5%" }}>{x}</a>)
        } */}

      {/* {
          isFilterApplied() ? <a href="#" onClick={handleClear} style={{ marginLeft: ".5%" }}>Clear</a> : <a href="#"></a>
        } */}
      {/* </div> */}


      <div style={gridStyle} className="ag-theme-quartz mx-auto">
        <AgGridReact<IPifDashboardData> ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          pagination={true}
          paginationPageSize={20}
          // paginationAutoPageSize={true}
          onGridReady={onGridReady}
          quickFilterText={quickFilterText}
        />
      </div>
    </div>

  );


}
export default GridExample;

