import "bootstrap/dist/css/bootstrap.min.css";
import {Nav, Navbar, Container} from 'react-bootstrap';
import { Link, useNavigate} from 'react-router-dom'
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import "./NavMenu.css";
import { auto } from "@popperjs/core";
import { RouteConstants } from "../../../App";
import { useNavStore } from "../../stores/NavStore";
import { useState } from "react";


export default function BreadCrumps (prop: any) {

    
    const navigate = useNavigate();
    var {storeFormType,updateFormType,awardType} = useNavStore();
    // TESTING
    console.log("INSIDE BREADCRUMPS");
    console.log ("Award Type is: " + awardType);
    var isDisabled = false;
    // Set linkText based on storeFormType
    var linkText = (awardType === "subaward") ? "Subawardee" : (awardType === "primeaward") ? "Primeawardee" : (awardType === "nonaward") ? "Non-Awardee" : (awardType === "revet") ? "Re-Vet": null;
    storeFormType = (storeFormType==="organization") ? "Organization" : (storeFormType==="individual") ? "Individual" : "";
    var navLink = RouteConstants.SUB_AWARD;

    if(prop.linkTitle){
      storeFormType = prop.linkTitle;
      isDisabled = true;
    }

    return (
      <>
        <Navbar collapseOnSelect  expand="md" variant="light" role="navigation"> 
          <Container fluid>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id='responsive-navbar-nav'>
              <Nav className="me-auto mt-0 content navbar-left navigation" >
                <Nav.Link  className="link-primary breadcrumb-item" onClick={()=>
                  navigate(RouteConstants.DASHBOARD)
                }> PVS Portal</Nav.Link>
                <Nav.Link  className="link-primary breadcrumb-item text-capitalize" 
                style={{display: isDisabled ? "none" : "block"}}
                onClick={()=>{
                  updateFormType("");
                  navigate(navLink)}}> {linkText} </Nav.Link>
                {storeFormType && <Nav.Link href="#" className="breadcrumb-item" style={{color:"#656469"}} aria-current="page" disabled > {storeFormType} </Nav.Link>}
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
    </> 
    )
  }

