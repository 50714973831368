import "../../common/Tiles/Tiles.css";
import UploadControl from "../../common/UploadControl";
import "./OrganizationInfo.css";
import { useEffect, useState } from "react";
import { IPicklist} from "../../../Interfaces/IPicklist";
import { ICitizenship } from "../../../Interfaces/ICitizenship";
import { getPicklist } from "../../../Services/Api";



export default function CitizenshipTemplate(prop: any) {

  var citControl:ICitizenship=
  {
    countryOfCitizenship : prop.value.countryOfCitizenhsip,
    governmentIdentificationT: prop.value.governmentIdentificationT,
    governmentIssuedPhotoId: prop.value.governmentPhotoId,
    countryIssuedBy: prop.value.countryofIssuance,
    usOrNonUs: prop.value.usOrNonUs,
    isDualCitizen: prop.value.isDualCitizen,
    individualId: prop.value.individualId,
    objectName: prop.value.objectName,
    elementId: prop.value.elementId   
  }


console.log(citControl)
  
const [orgIdentityTypes, setOrgIdentityTypes] = useState<IPicklist[]>([]);



const populateIdentityType = async () => {
  let response: IPicklist[] = await getPicklist("individual_ident_type");
  setOrgIdentityTypes(response);
}

useEffect(() => {
  populateIdentityType();
}, [])

const [selectedGovernmentIdentification, setGovernmentIdentificationType] = useState<string>('');

const handleGovernmentIdentificationTypeChange = (event: any) => {
  const selectedGovernmentIdentification = event.target.value;
  setGovernmentIdentificationType(selectedGovernmentIdentification);
}

   const handleCountrySelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        let selectedCountry = event.target.value;
        console.log(selectedCountry);
        prop.onChange(
          {
                  countryOfCitizenship : selectedCountry, 
                  governmentIdentificationT: prop.value.governmentIdentificationT,
                  governmentIssuedPhotoId: prop.value.governmentPhotoId,
                  countryIssuedBy: prop.value.countryofIssuance,
                  
                  usOrNonUs: prop.value.usOrNonUs,
                  isDualCitizen: prop.value.isDualCitizen,
                  individualId: prop.value.individualId,
                  elementId: prop.value.elementId   
          }
        )
  }


  function handleGovernmentIdentificationType(event: React.ChangeEvent<HTMLSelectElement>){
    const selectedgovernemntId = event.target.value;
    console.log(selectedgovernemntId);
    prop.onChange(
      {
        countryOfCitizenship : prop.value.countryOfCitizenship, 
        governmentIdentificationT: selectedgovernemntId,
        governmentIssuedPhotoId: prop.value.governmentPhotoId,
        countryIssuedBy: prop.value.countryofIssuance,
        
        usOrNonUs: prop.value.usOrNonUs,
        isDualCitizen: prop.value.isDualCitizen,
        individualId: prop.value.individualId,
        elementId: prop.value.elementId   
      }
    )
  }


  function handleCountryIssuedby(event: React.ChangeEvent<HTMLSelectElement>){
    const selectedCountry = event.target.value;
    console.log(selectedCountry);
    prop.onChange(
      {
        countryOfCitizenship : prop.value.countryOfCitizenship, 
        governmentIdentificationT: prop.value.governmentIdentificationT,
        governmentIssuedPhotoId: prop.value.governmentPhotoId,
        countryIssuedBy: selectedCountry,
        
        usOrNonUs: prop.value.usOrNonUs,
        isDualCitizen: prop.value.isDualCitizen,
        individualId: prop.value.individualId,
        elementId: prop.value.elementId   
      }
    )
  }


  function handleIdentNumChange(event: string){
    const input = event as string;
    prop.onChange(
      {
        countryOfCitizenship : prop.value.countryOfCitizenship, 
        governmentIdentificationT: prop.value.governmentIdentificationT,
        governmentIssuedPhotoId: input,
        countryIssuedBy: prop.value.countryIssuedBy,
        usOrNonUs: prop.value.usOrNonUs,
        isDualCitizen: prop.value.isDualCitizen,
        individualId: prop.value.individualId,
        elementId: prop.value.elementId   
      }
    )
}
  

  return (
    
    
    <>
      <div className="contentSection textFormat">
        <div className="row row-cols-2">
          <div className="col-6 ">
            <label className="label-div mb-1 asterisk">{prop.countryTitle}</label>
            <div className="control-div">
            {/* <select className="form-select form-select-lg" value={selectedCountry} onChange={handleCountrySelectChange}> */}
            <select className="form-select form-select-md" 
                value={citControl.countryOfCitizenship} 
                onChange={handleCountrySelectChange}
                disabled={prop.disabled}>
              <option value="" selected disabled hidden>
                Select Country of Citizenship
              </option>
              {prop.setCountryData?.map((item:any, index:any) => (
                    <option key={index} value={item.country_name}>{item.country_name}</option>
                  ))}
                </select>
            </div>
          </div>
          <div className="col flex-fill">
            <label className="label-div mb-1 asterisk">{prop.typeTitle}</label>
            <div className="control-div">
            <select className="form-select form-select-md" 
                    onChange={handleGovernmentIdentificationTypeChange}
                    disabled={prop.disabled}>
                  <option selected> Select Government Identity type</option>
                  {orgIdentityTypes?.map((item, index) => (
                    <option key={index} value={item.display_name}>{item.display_name}
                    </option>
                  ))}
                </select>
            </div>
          </div>
        </div>
        <div className="row row-cols-2">
          <div className="col-6 pe-0">
            <label className="label-div mb-1 asterisk">{prop.photoIdTitle}</label>
            <div style={{ marginLeft: "1em" }}>
              <UploadControl
                handleTextboxValueChange={handleIdentNumChange}
                // textboxValue={prop.uploadValue}
                textboxValue={prop.value.governmentPhotoId}
                title={"Insert Upload Title"}
                content={"Insert upload text here"}
                text={"*Upload ID"}
                selectedFiles={prop.selectedFiles}
                setSelectedFiles={prop.setSelectedFiles}
              >

              </UploadControl>
            </div>
          </div>
          <div className="col flex-fill">
            <label className="label-div mb-1 asterisk">{prop.issuanceTitle}</label>
            <div className="control-div">
               <select className="form-select form-select-md" 
                       value={citControl.countryIssuedBy} 
                       onChange={handleCountryIssuedby}
                       disabled={prop.disabled}>
              <option value="" disabled hidden selected>
                Select Country Issued By
              </option>
                 {prop.setCountryData?.map((item:any, index:any) => (
                    <option key={index} value={item.country_name}>{item.country_name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  }

