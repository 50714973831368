import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import icon_people from "../../../assets/images/svg/people.svg";
import icon_person from "../../../assets/images/svg/person.svg";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser, faLandmark } from '@fortawesome/free-solid-svg-icons';
import "./SelectFormType.css";
import { useNavigate } from "react-router-dom";
import { useNavStore } from "../../stores/NavStore";
import { text } from "stream/consumers";


export default function ShowFormType() {
    const { storeFormType, awardType,  } = useNavStore();
    // ******************************************************************************************
    // NOTE:  Keep value in small letters please---else the individual | organization won't show
    // ******************************************************************************************
    const items: { value: string, label: string, imageName: any, color: string }[] = [
        { value: 'organization', label: 'Organization', imageName: faLandmark, color: "white" },
        { value: 'individual', label: 'Individual', imageName: faUser, color: "white" }

    ];

    // Set Text on Screen based on awardType from NavStore:
    let name = "Awardee";

    if (awardType) {
        switch (awardType) {
            case "primeaward":
                name = "Prime Awardee";
                break;
            case "subaward":
                name = "Select Subawardee";
                break;
            case "nonaward":
                name = "Non-Awardee";
                break;
            default:
                name = "Awardee";
                break;
        }
    }

    const navigate = useNavigate();

    return (
        <div className="text-center container-fluid">
            <span className="heading">
                <h4 className="text-center">{name}</h4>
            </span>
            <div className="btnSection">
                {items.filter(x => x.value === storeFormType).map(item => (
                    <div key={item.value}>
                        <div className="card bg-secondary fs-6 text-white">
                            <label  htmlFor={item.value}>
                                <FontAwesomeIcon className="ps-1 pe-3 pt-2"  icon={item.imageName} fontSize={40} color={item.color} />
                                <span className="d-inline-block align-middle mb-2"> {item.label} </span>

                            </label>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
}


