import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";


import { Stack, Typography } from "@mui/material";
import { Props } from "@fortawesome/react-fontawesome";
import { BorderBottom } from "@mui/icons-material";


const grayColor = "#c3c3c3";
const usaidBlue = '#0164B9'
const fontSize = 12;
const style = {

};

const inputStyle = {
  minWidth: 50,
  color: '#808080',
}

const textStyle = {
  color: "black",
  fontSize: 12,
}

const FileUploadModal= (props:any) =>{
  const reviewerComments:string = "USAID Reviewer Comments:"
  const reviewermessage:string = "Your submission has been returned. "+ 
  "Please review the comments below and revise and resubmit your PIF submission.";
  return (
        <Stack direction={'column'} justifyContent={'center'} sx={{
          height: 70,
          marginTop:'10px',
          marginBottom:'2px',
          width:"100%",
          bgcolor: "background.paper",
          // borderTop: "0.5px solid black",
          // BorderBottom:"0.5px solid black",
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          p:6,
          // border: "0.5px solid black",
          borderRadius: '8px',
          boxShadow: "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
          spacing: 3
        }}>
            <Typography sx={{color: 'black', fontSize: 16, fontWeight: 'bold', textTransform: 'capitalize'}}>
              {reviewerComments}
            </Typography>
            <Typography sx={{color: 'black', fontSize: fontSize, fontWeight: 'bold', textTransform: 'none'}}>
              {reviewermessage}
            </Typography>
            <Typography sx={{color: 'black', fontSize: fontSize, fontWeight: 'bold', textTransform: 'none'}}>
              {props.comment}
            </Typography>
        </Stack>
  );
}

export default FileUploadModal;

