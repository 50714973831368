import { useState } from "react";
import "./OrganizationInfo.css";
import { IcontrolAttributes } from "../../../App.types";
import HelpDialog from "../../common/HelpDialog";

export default function AdditionalEmail(prop: {
  title: string;
  isAddEmailNeeded: boolean;
  controlAttributes: IcontrolAttributes;
  isRedStarNeeded: boolean;
  isPrimary: boolean;
  isPersonal: boolean;
  disabled: boolean;
}) {
  var [showAdditionalEmail, setshowAdditionalEmail] = useState(false);
  
  let orgText = 'Organization\'s email address\n';
      orgText += 'if no email exists enter - noname@noname.com ';

  let persText = "A personal email address, used by the individual.\n";
      persText += 'If no email exists, enter - noname@noname.com ';

  return (
    <>
      <div className="row">
        <div className="col text-start ms-0 ps-0">
          {/* <label className="label-div asterisk mb-1">{prop.title}</label> */}
          <label className={`mt-1 ms-4 ps-1 mb-1 ${prop.isRedStarNeeded? 'asterisk': '' }`}>{prop.title} </label>
          { prop.isPrimary && (<HelpDialog
                title= {prop.isPersonal? "Primary Personal Email Address" : "Organization Email Address"}
                context={prop.isPersonal? persText :orgText}
              />
          )}
        </div>
      </div>
      <div className="control-div">
        <input className="form-control input-group-lg"
          maxLength={100}
          type="textbox"
          placeholder={prop.isRedStarNeeded ? "Enter Organization Email: User@domain.com": "Enter Alternate Organization Email: User@domain.com"}
          onChange={prop.controlAttributes.primaryOnChange}
          value={prop.controlAttributes.primaryTextValue}
          disabled={prop.disabled}
        />
        { prop.isAddEmailNeeded && (
         <div className="col label-div ms-auto mt-0">
          <span
            style={{ color: "blue" }}
            onClick={() => setshowAdditionalEmail(true)}
          >
            <div className="text-end mt-1">
             + Add Email
            </div>
          </span>
        </div>
        )
      }

      </div>

      {showAdditionalEmail && (
        <>
          <div className="textFormat row">
            <div className="col">
              <label className="label-div mb-1 mt-1">{prop.title}</label>
            </div>
          </div>
          <div className="row control-div">
            <input className="form-control input-group-lg"
              maxLength={100}
              type="textbox"
              onChange={prop.controlAttributes.secondaryOnChange}
              value={prop.controlAttributes.secondaryTextValue}
              disabled={prop.disabled}
            />
             <div className="col label-div ms-auto">
              <span
                style={{ color: "blue" }}
                onClick={() => setshowAdditionalEmail(false)}
              >
                - Remove Email
              </span>
            </div>
          </div>
        </>
      )}
    </>
  );
}
