export enum StoreNames {
    AuthToken = "authTokens",
    Nav="nav",
    Country="CountryList",
    FormProps="FormProps"
  }

  export enum AppCookies {
    AuthCookie = "authDetail",
    JwtCookie = "jwt"
  }