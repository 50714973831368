import { create } from "zustand";

// Create type for custom hook

type State = {
    awardee: string | null;
    userName: string | null;
};

type Action = {
    setAwardee: (awardee: State['awardee'])=>void;
    setUserName: (userName: State['userName'])=>void;
    clearStore:()=>void;
};

// Create custom hook with a property and method to share globally Work Item: #24080
//
//  awardee:  user creating the PIF
//  org: string property representng Name of Prime Contractor, Grantee, or Recipient
//           from AwardInfo Component
//  setAwardee: method uses set function to change awardee Name 
//  setUsername: method uses set function to change awardee Name
export const useAwardeeStore = create<State & Action>( (set)=>({
    awardee: '',
    userName: '',
    setAwardee: (awardee) => set( () => ({ awardee:awardee})),
    setUserName: (userName) => set( () => ({ userName:userName})),
    clearStore:()=>set(()=>({awardee:null, userName:null}))
}));
