export interface IComment {
    id :string;
    commentObjectId:string;
    subAwdId:string;
    internalReason :string;
    externalReason :string;
    userName :string;
    comment :string;
    commentRead: boolean;
    commentDate:String;
}


const subAwardComment: IComment={
    commentObjectId:"",
    id : "",
    subAwdId :"",
    internalReason :"",
    externalReason :"",
    userName :"",
    comment :"",
    commentRead:false,
    commentDate: ""
}

export const initializeComment=()=>subAwardComment;
