import IDocument from "./IDocument";

export interface ICitizenship {
    governmentIdentificationT: string;
    countryIssuedBy: string;
    governmentIssuedPhotoId: string;
    countryOfCitizenship: string;
    usOrNonUs: string;
    isDualCitizen: boolean;
    individualId: string;
    objectName: string;
    elementId: number;
}



const initCitizenship: ICitizenship = {
    governmentIdentificationT: "",
    countryIssuedBy: "",
    governmentIssuedPhotoId: "",
    countryOfCitizenship: "",
    usOrNonUs: "",
    isDualCitizen: false,
    individualId: "",
    objectName: "",
    elementId: 0,
}

const init5Citizenship: ICitizenship[] = [
    {
        governmentIdentificationT: "",
        countryIssuedBy: "",
        governmentIssuedPhotoId: "2222222222",
        countryOfCitizenship: "",
        usOrNonUs: "",
        isDualCitizen: false,
        individualId: "",
        objectName: "",
        elementId: 0,
    },
    {
        governmentIdentificationT: "",
        countryIssuedBy: "",
        governmentIssuedPhotoId: "2222222222",
        countryOfCitizenship: "",
        usOrNonUs: "",
        isDualCitizen: false,
        individualId: "",
        objectName: "",
        elementId: 1,
    },
    {
        governmentIdentificationT: "",
        countryIssuedBy: "",
        governmentIssuedPhotoId: "",
        countryOfCitizenship: "",
        usOrNonUs: "",
        isDualCitizen: false,
        individualId: "",
        objectName: "",
        elementId: 2,
    },

    {
        governmentIdentificationT: "",
        countryIssuedBy: "",
        governmentIssuedPhotoId: "",
        countryOfCitizenship: "",
        usOrNonUs: "",
        isDualCitizen: false,
        individualId: "",
        objectName: "",
        elementId: 3,
    },


    {
        governmentIdentificationT: "",
        countryIssuedBy: "",
        governmentIssuedPhotoId: "",
        countryOfCitizenship: "",
        usOrNonUs: "",
        isDualCitizen: false,
        individualId: "",
        objectName: "",
        elementId: 4,
    },

]

export const initializeICitizenship = initCitizenship
export const initialize5ICitizenship = init5Citizenship
