import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import { useEffect, useRef, useState } from 'react';
import "./FormNavigation.css";
import { useLocation, useNavigate } from "react-router-dom";
import { AwardTypes, RouteConstants } from "../../../App";
import { NavigationCards } from "./NavigationCards";
import { useNavStore } from "../../stores/NavStore";
import { v4 as uniqueKey } from "uuid";
import ShowFormType from "../selectFormType/ShowFormType";
import { useFormProps } from "../../stores/FormProperties";
import ReturnedLabel from "../../common/ReturnedLabel/ReturnedLabel";

export default function NavigateForms() {
    const activeCards = useRef<Set<string>>(new Set<string>());
    const { storeFormType, awardType } = useNavStore();
    const {updateFormProperties,clearFormProperties,formProperties} = useFormProps();
    const { pathname } = useLocation();

    const enableCards = () => {
        const switchOn = pathname.split("/").slice(-1)[0];

    if (switchOn.length > 0) {
            switch (switchOn) {
            
                //@ts-ignore
                case "review":
                   activeCards.current.add("review");
                //@ts-ignore
                case "keyindividual":
                    activeCards.current.add("keyindividual");
                //@ts-ignore
                case "organization":
                    activeCards.current.add("organization")
                //@ts-ignore
                case "awardinfo":
                    activeCards.current.add("awardinfo");
            }
        }
    }

    useEffect(() => {
        enableCards();
    }, [pathname])


    return (<>
        <ShowFormType />
        {/* Sub Awards */}
        {(storeFormType === "organization") && (awardType === AwardTypes.SUB_AWARD) &&
            <div className="navigation mt-4 text-center grid-container justify-content-center">
                <NavigationCards key={uniqueKey()} name={"Award Info"} step={"Step 1"} id={"awardinfo"} activeCards={ activeCards.current} route={RouteConstants.SUB_AWARD_INFO} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Organization Info"} step={"Step 2"} id={"organization"} activeCards={ activeCards.current} route={RouteConstants.SUBAWARD_ORGANIZATION} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Add Key Individuals"} step={"Step 3"} id={"keyindividual"} activeCards={ activeCards.current} route={RouteConstants.SUBAWARD_KEY_INDIVIDUAL} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Review & Submit"} step={"Step 4"} id={"review"} activeCards={ activeCards.current} route={RouteConstants.SUBAWARD_REVIEW} />
            </div>
        }

        {
            (storeFormType === "individual") && (awardType === AwardTypes.SUB_AWARD) &&
            < div className="navigation mt-4 text-center grid-container justify-content-center">
                <NavigationCards key={uniqueKey()} name={"Award Info"} step={"Step 1"} id={"awardinfo"} activeCards={ activeCards.current} route={RouteConstants.SUB_AWARD_INFO} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Individual Info "} step={"Step 2"} id={"keyindividual"} activeCards={ activeCards.current} route={RouteConstants.SUBAWARD_KEY_INDIVIDUAL} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Review & Submit"} step={"Step 3"} id={"review"} activeCards={ activeCards.current} route={RouteConstants.SUBAWARD_REVIEW} />
            </div >
        }


        {/* // Non Awards  */}
        {(storeFormType === "organization") && (awardType === AwardTypes.NON_AWARD) &&

            <div className="navigation mt-4 text-center grid-container justify-content-center">
                <NavigationCards key={uniqueKey()} name={"Award Info"} step={"Step 1"} id={"awardinfo"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_INFO} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Organization Info"} step={"Step 2"} id={"organization"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_ORGANIZATION} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Add Key Individuals"} step={"Step 3"} id={"keyindividual"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_KEY_INDIVIDUAL} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Review & Submit"} step={"Step 4"} id={"review"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_REVIEW} />
            </div>
        }

        {
            (storeFormType === "individual") && (awardType === AwardTypes.NON_AWARD) &&
            < div className="navigation mt-4 text-center grid-container justify-content-center">
                <NavigationCards key={uniqueKey()} name={"Award Info"} step={"Step 1"} id={"awardinfo"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_INFO} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Individual Info "} step={"Step 2"} id={"keyindividual"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_KEY_INDIVIDUAL} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Review & Submit"} step={"Step 3"} id={"review"} activeCards={ activeCards.current} route={RouteConstants.NON_AWARD_REVIEW} />
            </div >
        }
        {/* Prime Awards */}

        {(awardType === AwardTypes.PRIME_AWARD) &&

            <div className="navigation mt-4 text-center grid-container justify-content-center">
                <NavigationCards key={uniqueKey()} name={"Award Info"} step={"Step 1"} id={"awardinfo"} activeCards={ activeCards.current} route={RouteConstants.PRIME_AWARD_INFO} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Organization Info"} step={"Step 2"} id={"organization"} activeCards={ activeCards.current} route={RouteConstants.PRIME_AWARD_ORGANIZATION} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Add Key Individuals"} step={"Step 3"} id={"keyindividual"} activeCards={ activeCards.current} route={RouteConstants.PRIME_AWARD_KEY_INDIVIDUAL} />
                <div className="the-line"> </div>
                <NavigationCards key={uniqueKey()} name={"Review & Submit"} step={"Step 4"} id={"review"} activeCards={ activeCards.current} route={RouteConstants.PRIME_AWARD_REVIEW} />
            </div>
        }

        {formProperties.vsuStatus === "Returned" && <ReturnedLabel comment={formProperties.reason} />}
    </>
    )
}