import "bootstrap/dist/css/bootstrap.min.css";
import "@fontsource/source-sans-pro"; // Defaults to weight 400
import "@fontsource/source-sans-pro/400.css"; // Specify weight
import './Login.css';

import { SetStateAction, useEffect, useState } from "react";
import { redirect, useNavigate } from "react-router-dom";
import { BrowserRouter as Router, Route, useLocation } from "react-router-dom";
import axios from "axios";
import React from "react";
import { getLoginUrl } from "../../Services/Api";
export default function Login() {

  let loginUrl='';
  useEffect(()=>{
    const getUrl=  async ():Promise<string> =>await getLoginUrl().then((x)=>loginUrl=x);
    getUrl();
  },[]);

  const clearPreviousData =()=>{

  }

  // const handleLogin = () => {
  //   window.location.href=process.env.REACT_APP_API_OTDS_URL as string;
  // }
  return (
    <div className="container-fluid login">
      <div style={{ textAlign: "center" }}>
        <button type="submit" className="btn btn-primary btn-block mb-4"
          onClick={() => {
            //Clear 
            clearPreviousData();
            
            if (loginUrl !== '')
              window.location.href = loginUrl
          }}>
          CLICK HERE TO LOGIN
        </button>
      </div>
    </div>
  );
}
